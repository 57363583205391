<div id="sh-header-toolbar-notifications-button-div">
	<button
		mat-icon-button
		(click)="notificationsPopup()"
		id="sh-header-toolbar-notifications-button"
		aria-label="notifications"
	>
		<mat-icon
			class="notification-icon-style"
			fontIcon="notifications"
			[matBadge]="notificationsCount > 99 ? '99+' : notificationsCount"
			[matBadgeHidden]="notificationsCount === 0"
			matBadgeColor="accent"
		></mat-icon>
	</button>
</div>
